import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import {
  Body,
  Bold,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import Section from "../components/Section"
import Banner from "../components/Banner"
import MainPoint from "../components/MainPoint"

import styled from "styled-components"
import opengraph from "../images/opengraph.png"

const MainPointBullet = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
`

function EventManagementPage({ data }) {
  return (
    <Layout pageTitle="Event Management" image={opengraph}>
      <Banner
        text={[
          <Header1>
            Simple and <Header1Accent>effortless</Header1Accent>
          </Header1>,
          <Header3>
            From regional affinity group get-togethers to multi-day campus
            reunions, simplify the creation, promotion, and management of
            events.
          </Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
        ]}
        image={data.banner}
      />

      <Section>
        <MainPoint
          text={[
            <Header2>Maximize event impact</Header2>,
            <Body>
              Create and manage highly successful events with minimal effort.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          text={[
            <MainPointBullet>
              <Header5>More Attendees with Less Effort</Header5>
              <Body>
                Studies show that a major driver of an alum’s attendance is a
                personal connection to other attendees. CueBack’s AI engine
                creates a personalized <Bold>Smart Attendees List™</Bold> for
                each alum viewing the event.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Promote Personalized Events</Header5>
              <Body>
                Determine which events to invite your alumni to based on their
                personal preferences, interests and affiliations.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Highlight Your Impact</Header5>
              <Body>
                Analyze event success by measuring attendance, financial
                metrics, attendees’ capacity to give, and impact on willingness
                to give.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section1}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Optimize your financial goals</Header2>,
            <Body>
              Each event and activity can be customized with individual pricing
              and promotions that maximize return while minimizing risk.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          reversed
          text={[
            <MainPointBullet>
              <Header5>Offer Flexible Pricing</Header5>
              <Body>
                Achieve your financial objectives by adding discounts,
                promotions, or surcharges.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Save Money</Header5>
              <Body>
                Why throw money away? We pass on the cost of credit card
                processing directly, with no added fees.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Minimize Risk</Header5>
              <Body>
                Define your refund and cancellation policies to mitigate your
                financial exposure.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section2}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Hassle-free administration</Header2>,
            <Body>
              Save valuable time by letting CueBack automate many of the event
              management tasks.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          text={[
            <MainPointBullet>
              <Header5>Empower Alumni Groups</Header5>
              <Body>
                Equip your departments, chapters and affinity groups with a
                comprehensive application to manage and promote their own
                events. Outsource the work while maintaining brand integrity and
                oversight.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Automated Drip Marketing</Header5>
              <Body>
                Stay top-of-mind with donors and prospects through automated
                event notifications. Decide what to say and when to say it;
                we’ll handle the rest.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Identify and Manage Volunteers</Header5>
              <Body>
                Providing volunteer opportunities for alumni is a great way to
                strengthen alumni identity (a proven driver of giving). Save
                time and effort by automating the volunteer registration
                process.
              </Body>
            </MainPointBullet>,
          ]}
          image={data.section3}
        />
      </Section>
      <Section>
        <MainPoint
          text={[
            <Header2>Interactive reports</Header2>,
            <Body>
              Have complete access to all of your alumni’s engagement data in
              one place. Our reports will provide you with unprecedented insight
              into their interests, connections, attendance, giving habits, and
              more.
            </Body>,
          ]}
          reversed
        />
        <MainPoint
          reversed
          text={[
            <MainPointBullet>
              <Header5>Collect Valuable Information</Header5>
              <Body>
                Build a database of qualitative and quantitative information
                that can be leveraged by multiple stakeholders.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Increase Giving</Header5>
              <Body>
                Understand which events attract the highest capacity attendees,
                targeted prospects, and their influencers, then use this
                information to plan future events.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Engage Better</Header5>
              <Body>
                Generate aggregate and individual reports about your alumni to
                better inform your Advancement and Alumni Relations strategies.
              </Body>
            </MainPointBullet>,
            <MainPointBullet>
              <Header5>Track Event Metrics</Header5>
              <Body>Monitor all relevant event data in real time.</Body>
            </MainPointBullet>,
          ]}
          image={data.section4}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    lightImage: allFile(
      filter: { relativePath: { eq: "index-bg-light.jpeg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, aspectRatio: 1)
        }
      }
    }
    banner: allFile(
      filter: {
        relativePath: {
          eq: "eventManagement/steven-abraham-L9BHtFxyaak-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    section1: allFile(
      filter: {
        relativePath: {
          eq: "eventManagement/mikael-kristenson-3aVlWP-7bg8-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    section2: allFile(
      filter: {
        relativePath: {
          eq: "eventManagement/morgan-housel-PcDGGex9-jA-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
    section3: allFile(
      filter: {
        relativePath: {
          eq: "eventManagement/kylie-haulk-o9O-wBHfIbs-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    section4: allFile(
      filter: {
        relativePath: {
          eq: "eventManagement/towfiqu-barbhuiya-Q69veNk1iJQ-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default EventManagementPage
